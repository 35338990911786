import React from 'react';
import './AppFooter.css';
import { Col, Divider, Row} from 'antd';
import img from "../../assets/img.svg";
import '../header/AppHeader.css';
import { FacebookOutlined,InstagramOutlined,TwitterOutlined,LinkedinOutlined } from '@ant-design/icons';
import {Space} from 'antd';
import { FooterItem } from './FooterItem.js';

export const AppFooter = ({scrollToSection}) => {

    const handleFooterClick = (section) => {
        scrollToSection.current(section);
    };

    return (
        <div className='footer-page'>
            |
            <Divider className='footer-divider'/>
            <div className='footer-logo'>
                <img src={img} alt='Logo' className='app-logo'></img>
                <div className='main-title'>Club Sports Solutions</div>
                <Space size={20} style={{fontSize:"x-large", paddingRight: '80px'}}>
                    <a 
                        href="https://www.instagram.com/company/club-sports-solutions/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }} // Optional: Removes underline if needed
                        >
                        <InstagramOutlined style={{backgroundColor:'red',color:'white',borderRadius:'5%'}}/>
                    </a>
                    <a 
                        href="https://www.facebook.com/company/club-sports-solutions/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }} // Optional: Removes underline if needed
                        >
                        <FacebookOutlined style={{backgroundColor:'blue',color:'white',borderRadius:'5%'}}/>
                    </a>
                    <a 
                        href="https://www.twitter.com/company/club-sports-solutions/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }} // Optional: Removes underline if needed
                        >
                        <TwitterOutlined style={{backgroundColor:'blue',color:'white',borderRadius:'50%'}}/>
                    </a>
                    <a 
                        href="https://www.linkedin.com/company/club-sports-solutions/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }} // Optional: Removes underline if needed
                    >
                        <LinkedinOutlined style={{backgroundColor:'rgb(73,73,182)',borderRadius:'7%',color:'white'}}/>
                    </a>                    
                </Space>
            </div>
            <div>
                <Row className='row-ref'>
                    <Col><FooterItem scrollToSection={scrollToSection} footerTitle="Company" footerRefs={["About", "About", "About"]} footerItems={["About Us","Careers","ContactUs"]}/></Col>
                    <Col><FooterItem footerTitle="Features" footerItems={[
                        "Online Court Reservation",
                        "Tournament Planner",
                        "Membership Management",
                        "Realtime Dashboards",
                        "Realtime Notifications",
                        "Student Progress Tracker",
                        "Inventory management",
                        "Inbuilt POS"]}/></Col>
                    <Col><FooterItem footerTitle="Resources" footerItems={["Demo Video","Blog","News Letters"]}/></Col>
                    <Col><FooterItem footerTitle="Contact Us" footerItems={["Facebook","Instagram","Phone","Live Chat"]}/></Col>
                    <Col><FooterItem footerTitle="FAQs" footerItems={[
                        "How to contact ClubSportSolutions",
                        "What is the pricing model",
                        "Questions on Subscription",
                        "All other Questions"]}/></Col>
                </Row> 
            </div>
             <p className='copy-rights'> &copy; All rights reserved, ClubSportsSolutions L.L.C.</p>
        </div>
    );
};
