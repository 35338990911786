import React, { useEffect, useState } from 'react';
import { Row,Col, Button } from 'antd';
import './AboutUsPage.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { DownOutlined } from '@ant-design/icons';

export const AboutUsPage=()=>{
    useEffect(()=>{
        Aos.init({duration:2000})
    },[])

    const [showAboutUs, setShowAboutUs] = useState(true);
    const image = '/CSS-logo.png'; 
    const title = 'About Us';
    const description = 'At Club Sports Solutions, we offer an all-in-one platform designed to streamline facility management with features like Online Court Reservation for easy booking control, a Tournament Planner with real-time updates and dashboards, and Membership Management to track subscriptions effortlessly. Our Dashboards provide visual insights into your business’s performance, while Integrated Notifications keep users informed through SMS and email. Additionally, we provide a Student Progress Tracker for training oversight, Inventory Management to keep stock organized, and an Inbuilt POS system for seamless sales—all accessible through our user-friendly mobile app and website, making facility management both efficient and convenient.';

    // // Function to handle scroll event
    // const handleScroll = () => {
    //     if (window.scrollY > 50) {
    //         setShowAboutUs(false);
    //     } else {
    //         setShowAboutUs(true);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    //     };
    // }, []);

return (
    
    <div className='new-feature'>
        <Row span={12} className='feature-row'>
            <Col span={12} className="feature-col">
                <img className='new-image' src={image} alt='Services'></img>
            </Col>
            <Col span={12} className='feature-col-text' data-aos='zoom-in'> 
                <h1 className='aboutus-title'>{title}</h1>
                <p className='content'>{description}</p>
                <Button className="demo-button">Request a Demo Today</Button>
            </Col>
            {showAboutUs && (
                            <div className="features-section">
                                <p className="features-text">Our Features</p>
                                <DownOutlined className="features-down-arrow-icon" />
                            </div>
                        )}
        </Row>
    </div>
)
};