import React, {useRef, useState} from 'react';
import './AppBody.css';
import './IntroPage.css';
import { IntroPage } from './IntroPage.js';
import { LeftFeature} from './LeftFeature.js';
import { RightFeature} from './RightFeature.js';
import { AboutUsPage } from './AboutUsPage.js';
import { FeaturesTitlePage } from './FeaturesTitlePage.js';
import { Modal } from 'antd';

// Warning component to show on mobile/tablet devices
const ContactUs = () => (
  <div style={{ textAlign: 'center', padding: '20px' }}>
    <h1>Page Under Construction</h1>
    <p>But we got you covered..! </p>
    <p>Please <b><i>email to us at, info@clubsportssolutions.com ,</i></b> we will respond within one business day. Have a great day..!</p>
    <img 
      src='/contactus_uder_construction.png' 
      height={300} 
      style={{ 
        borderRadius: '15px', 
        boxShadow: '0px 4px 4px rgba(238, 32, 5, 0.4)' 
      }} 
    />
  </div>
);

const AppBody = ( {scrollToSection}) => {

    const introRef = useRef(null);
    const aboutUsRef = useRef(null);
    const servicesRef = useRef(null);
    const contactRef = useRef(null);
    const courtReservationRef = useRef(null);
    const trournamentPlannerRef = useRef(null);
    const membershipManagementRef = useRef(null);
    const dashbordsRef = useRef(null);
    const notificationsRef = useRef(null);
    const studentProgressRef = useRef(null);
    const inventoryManagementRef = useRef(null);
    const inbuiltPosRef = useRef(null);

    const [isContactModalVisible, setIsContactModalVisible] = useState(false);

    scrollToSection.current = (section) => {
      switch (section) {
          case 'Home':
              introRef.current?.scrollIntoView({ behavior: 'smooth' });
              break;
          case 'About':
              aboutUsRef.current?.scrollIntoView({ behavior: 'smooth' });
              break;
          case 'Services':
              servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
              break;
          case 'Contact':
              setIsContactModalVisible(true);
              // contactRef.current?.scrollIntoView({ behavior: 'smooth' });
              break;
          default:
              break;
      }
    };

    const handleModalClose = () => {
      setIsContactModalVisible(false);
    };

    const aboutUs2  = '/aboutus.png';
    const courtreservation = '/intropics/courtreservation.png';
    const tournamentPlanner = '/intropics/tournamentplanner.png';
    const membershipManagement = '/intropics/membershipManagement.png';
    const realtimeDashboards = '/intropics/Dashboards.png';
    const realtimeNotfications = '/intropics/Notifications.png';
    const curriclumTrackerTool = '/intropics/curriculumTracker.png';
    const inventoryManagement = '/intropics/inventoryManagement.png';
    const inbuiltPos = '/featurespics/POS.png';

    const linDan = '/animepics/LinDan.png';
    const kentoMomota = '/animepics/kentomomota.png';
    const leeChongWei = '/animepics/leeChongWei.png';
    const peterHoegGade = '/animepics/PeterHoegGade.png';
    const sainaNehwal = '/animepics/SainaNehwal.png';
    const susiSunati = '/animepics/SusiSUnati.png';
    const taufikHidayat = '/animepics/TaufikHidayat.png';
    const victorAxelsen = '/animepics/VictorAxelsen.png';

    const tournamentPlannerFeatuers = [
        "Seamless Tournament Setup",
        "Integrated Notifications",
        "Comprehensive Dashboards",
        "Efficient Management"
      ];

    const onlineCourtBookingFeatures = [
        "Effortless Online Booking",
        "Full Control for Owners",
        "Accessible Anywhere",
        "Convenient and User-Friendly"
    ];

    const membershipManagementFeatures = [
        "Easy Member Tracking",
        "Subscription Management",
        "Automated Reminders",
        "Flexible Access Control",
        "Analytics and Reports",
        "Mobile and Web Accessibility"
      ];

      const realtimeDashboardsFeatures = [
        "Instant Data Updates",
        "Customizable Views",
        "Visual Insights",
        "Track Key Metrics",
        "Interactive Components",
        "Mobile-Responsive"
      ];

      const realtimeNotificationsFeatures = [
        "Instant Alerts",
        "Customizable Preferences",
        "Automated Reminders",
        "Personalized Messages",
        "Activity Tracking",
        "Multi-Device Support"
      ];

      const curriculumTrackerToolFeatures = [
        "Progress Monitoring",
        "Customizable Curriculum Paths",
        "Milestone Tracking",
        "Performance Insights",
        "Automated Notifications",
        "Accessible Across Devices"
      ];

      const inventoryManagementFeatures = [
        "Real-Time Stock Tracking",
        "Automated Reordering",
        "Detailed Item Records",
        "Centralized Management",
        "Insights and Reports",
        "Mobile and Web Access"
      ];

      const inbuiltPOSFeatures = [
        "Seamless Transactions",
        "Inventory Integration",
        "Multiple Payment Options",
        "Sales Analytics",
        "User-Friendly Interface",
        "Multi-Device Compatibility"
      ];

    return (
        <div>
            <div ref={introRef}>
              <IntroPage/>
            </div>
            <div ref={aboutUsRef}>
            <AboutUsPage 
                image={aboutUs2} 
                title={'About Us'} 
                description={'At Club Sports Solutions, we offer an all-in-one platform designed to streamline facility management with features like Online Court Reservation for easy booking control, a Tournament Planner with real-time updates and dashboards, and Membership Management to track subscriptions effortlessly. Our Dashboards provide visual insights into your business’s performance, while Integrated Notifications keep users informed through SMS and email. Additionally, we provide a Student Progress Tracker for training oversight, Inventory Management to keep stock organized, and an Inbuilt POS system for seamless sales—all accessible through our user-friendly mobile app and website, making facility management both efficient and convenient.'}/>
            </div>
            <div ref={servicesRef}>
              <FeaturesTitlePage/>
              <RightFeature 
                  ref={courtReservationRef}
                  features={onlineCourtBookingFeatures}
                  animeSrc={linDan} 
                  animeQuote="To me, success is not about winning; it’s about growing and always challenging myself." 
                  animeName="Lin Dan"
                  imageSrc={courtreservation} title={'Online Court Reservation'} 
                  description={'Effortlessly manage court reservations with our online booking feature. Your customers can conveniently reserve courts through our platform, while you maintain full control over court availability. As the owner, you can easily update schedules, and modify or cancel reservations—all accessible from our mobile app or webpage.'} 
                  animation={'zoom-in'}/>
              <LeftFeature 
                  ref={trournamentPlannerRef}
                  features={tournamentPlannerFeatuers}
                  animeSrc={kentoMomota} 
                  animeQuote="Even when I lose, I learn from my mistakes and work to become stronger." 
                  animeName="Kento Momota"
                  imageSrc={tournamentPlanner} title={'Tournament Planner'} 
                  description={'..........Your feature detail content here...'} 
                  animation={'fade-in'}/>
              <RightFeature 
                ref={membershipManagementRef}
                  features={membershipManagementFeatures}
                  animeSrc={leeChongWei} 
                  animeQuote="I don’t want to be a legend. I just want to be myself." 
                  animeName="Lee Chong Wei"
                  imageSrc={membershipManagement} 
                  title={'Membership Management'} 
                  description={'22..........Your feature detail content here...'}  
                  animation={'fade-left'}/>
              <LeftFeature 
                  ref={dashbordsRef}
                  features={realtimeDashboardsFeatures}
                  animeSrc={peterHoegGade} 
                  animeQuote="In badminton, it’s not just about speed. It’s about patience, strategy, and using your head." 
                  animeName="Peter Hoeg Gade"
                  imageSrc={realtimeDashboards} 
                  title={'Realtime Dashboards'} 
                  description={'..........Your feature detail content here...'} 
                  animation={'fade-right'}/>
              <RightFeature 
                  ref={notificationsRef}
                  features={realtimeNotificationsFeatures}
                  animeSrc={victorAxelsen} 
                  animeQuote="Hard work beats talent when talent doesn’t work hard." 
                  animeName="Victor Axelsen"
                  imageSrc={realtimeNotfications} 
                  title={'Realtime Notifications'} 
                  description={'33..........Your feature detail content here...'} 
                  animation={'fade-in'}/>
              <LeftFeature 
                  ref={studentProgressRef}
                  features={curriculumTrackerToolFeatures}
                  animeSrc={sainaNehwal} 
                  animeQuote="I want to be the best, it’s not about the ranking, it’s about being consistent." 
                  animeName="Saina Nehwal"
                  imageSrc={curriclumTrackerTool} 
                  title={'Curriculum Tracker Tool'} 
                  description={'..........Your feature detail content here...'} 
                  animation={'fade-down'}/>
              <RightFeature 
                  ref={inventoryManagementRef}
                  features={inventoryManagementFeatures}
                  animeSrc={susiSunati} 
                  animeQuote="Success is not a matter of luck, but of hard work, dedication, and strong will." 
                  animeName="Susi Sunati"
                  imageSrc={inventoryManagement} 
                  title={'Inventory management'} 
                  description={'33..........Your feature detail content here...'} 
                  animation={'fade-in'}/>
              <LeftFeature 
                  ref={inbuiltPosRef}
                  features={inbuiltPOSFeatures}
                  animeSrc={taufikHidayat} 
                  animeQuote="If you want to be the best, you have to be willing to do what others are not willing to do." 
                  animeName="Taufik Hidayat"
                  imageSrc={inbuiltPos} 
                  title={'Inbuilt POS'} 
                  description={'..........Your feature detail content here...'} 
                  animation={'fade-down'}/>
            </div>

            {/* Contact Us Modal */}
              <Modal
                title="Contact Us"
                visible={isContactModalVisible}
                onCancel={handleModalClose}
                footer={null}
                centered
              >
                <ContactUs />
              </Modal>
        </div>
    );
};

export default AppBody;